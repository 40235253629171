import {Media} from "../../../model/Media/Media";
import outside from "../../../assets/img/massage-nelly/outside.png";
import outside2 from "../../../assets/img/massage-nelly/outside_2.png";
import yoga_room from "../../../assets/img/massage-nelly/yoga_room.png";
import herbal_tea from "../../../assets/img/massage-nelly/herbal_tea.png";
import office from "../../../assets/img/massage-nelly/office.png";
import kitchen from "../../../assets/img/massage-nelly/kitchen.png";
import entrance from "../../../assets/img/massage-nelly/entrance.png";
import cuts from "../../../assets/img/massage-nelly/cuts.png";
import rest from "../../../assets/img/massage-nelly/rest.png";
import plansaxonometry from "../../../assets/img/massage-nelly/plans_axonometry.png";
import ProjectTemplate from "../../../components/Projects/ProjectTemplate/ProjectTemplate";

const MassageNelly = () => {

    const medias: Media[] = [
        {uri: outside2, title: "Visuel de l'extérieur", type: "IMAGE"},
        {uri: outside, title: "Visuel de l'extérieur", type: "IMAGE"},
        {uri: yoga_room, title: "Visuel de la salle de Yoga", type: "IMAGE"},
        {uri: entrance, title: "Visuel de l'entrée", type: "IMAGE"},
        {uri: herbal_tea, title: "Visuel du salon de thé", type: "IMAGE"},
        {uri: rest, title: "Salle repos lecture", type: "IMAGE"},
        {uri: office, title: "Visuel bureaux du personnel", type: "IMAGE"},
        {uri: kitchen, title: "Visuel cuisine du personnel", type: "IMAGE"},
    ];

    const overviewParagraphs = [
        "La demande vise à transformer une grange familiale en un centre associatif de bien-être ouvert au public. Ce projet inclut l'aménagement des espaces extérieurs pour créer différents pôles d'activités, l'installation d'un espace bien-être intérieur, l'intégration d'une boutique, de zones de travail, ainsi que le développement d'une identité visuelle propre. L'objectif est de valoriser le patrimoine existant tout en respectant et en s'harmonisant avec l'environnement naturel du site.",
        "Par ailleurs, le projet cherche à promouvoir le bien-être en optimisant l'usage des espaces et leurs fonctionnalités, tout en adoptant une approche respectueuse de l'environnement et à faible impact.",
        "Le nom « Un Brin d'Évasion » a été sélectionné pour instaurer une ambiance permettant au visiteur de se détacher du quotidien et de se diriger vers une zone de tranquillité intime. Le mot « brin » fait référence à la douceur et à la légèreté, indiquant une fuite discrète et apaisante. Il représente aussi la nature environnante. Le terme, associé à « évasion », évoque le concept de se détourner du stress urbain pour trouver un refuge dans un refuge paisible. Le voyage est aussi inclus, représentant un voyage interne par le biais de différentes méthodes. « Un Brin d'Évasion » propose donc un endroit où les clients peuvent se relaxer, se recharger et se fuir dans une zone hors du temps."
    ];

    const overviewImage = {uri: cuts, title: "Coupes", type: "IMAGE"};

    const overviewParagraphsImage = {uri: plansaxonometry, title: "Plans et axonométrie", type: "IMAGE"}

    return (

        <ProjectTemplate
            background="bg-massagenelly-presentation-background"
            title="Les massages de Nelly"
            summary="Saint Pierre du Mont, 40280"
            galleryMedias={ medias }
            overviewImage={ overviewImage }
            overviewParagraphs={ overviewParagraphs }
            overviewParagraphsImage={ overviewParagraphsImage }
        />
    );
}

export default MassageNelly;