type DiverseProjectPresentationProps = {
    title: string;
    paragraphs: string[];
};

const DiverseProjectPresentation = (props: DiverseProjectPresentationProps) => {

    return (
        <div className="flex flex-col w-1/2 px-8 justify-center space-y-10">
            <h1 className="text-5xl xl:text-7xl italic font-gildadisplay">{ props.title }</h1>
            <p className="font-gildadisplay space-y-8 xl:text-xl">

                {props.paragraphs.map((paragraph, index) => (
                    <p key={paragraph}>{paragraph}</p>
                ))}
            </p>
        </div>
    );
};

export default DiverseProjectPresentation;