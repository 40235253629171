import {Media} from "../../../model/Media/Media";
import ProjectGalleryMedia from "./ProjectGalleryImage/ProjectGalleryMedia";
import React from "react";

type DiverseProjectGalleryProps = {
    medias: Media[];
};

const DiverseProjectGallery = (props: DiverseProjectGalleryProps) => {

    const getGridStyle = () => {

        const defaultStyle = "columns-1 gap-5 sm:gap-8 [&>img:not(:first-child)]:mt-8";

        if (props.medias.length < 3) {

            return `${defaultStyle} sm:columns-1`
        } else {

            return `${defaultStyle} sm:columns-2`
        }
    }

    return (
        <div className="flex flex-col w-1/2 justify-center px-4">
            <div className={ getGridStyle() }>
                {props.medias.map((media, index) => (
                    <ProjectGalleryMedia key={ media.uri } id={ index } media={ media } />
                ))}
            </div>
        </div>
    );
};

export default DiverseProjectGallery;