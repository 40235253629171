import ProjectTemplate from "../../../components/Projects/ProjectTemplate/ProjectTemplate";
import outside3 from "../../../assets/img/chateau-chantegrive/outside_3.png";
import outside4 from "../../../assets/img/chateau-chantegrive/outside_4.png";
import outside5 from "../../../assets/img/chateau-chantegrive/outside_5.png";
import room1 from "../../../assets/img/chateau-chantegrive/room_1.png";
import room2 from "../../../assets/img/chateau-chantegrive/room_2.png";
import room3 from "../../../assets/img/chateau-chantegrive/room_3.png";
import room4 from "../../../assets/img/chateau-chantegrive/room_4.png";
import tastingroom from "../../../assets/img/chateau-chantegrive/tasting_room.png";
import developmentplan from "../../../assets/img/chateau-chantegrive/development-plan.png";
import livingroom from "../../../assets/img/chateau-chantegrive/living_room.png";
import {Media} from "../../../model/Media/Media";

const Chantegrive = () => {

    const medias: Media[] = [
        {uri: room4, title: "Visuel d'une chambre", type: "IMAGE"},
        {uri: room2, title: "Visuel d'une chambre", type: "IMAGE"},
        {uri: livingroom, title: "Visuel d'un salon", type: "IMAGE"},
        {uri: room1, title: "Visuel d'une chambre", type: "IMAGE"},
        {uri: outside4, title: "Visuel de l'extérieur", type: "IMAGE"},
        {uri: outside5, title: "Visuel de la piscine", type: "IMAGE"},
        {uri: room3, title: "Visuel d'une chambre", type: "IMAGE"},
        {uri: outside3, title: "Visuel de l'extérieur", type: "IMAGE"},
        {uri: tastingroom, title: "Visuel de la salle de dégustation", type: "IMAGE"},
    ];

    const paragraphs = [
        "Bienvenue dans ce projet d’exception niché à proximité du prestigieux Château Chantegrive, à Podensac. Notre mission était de transformer un ancien chai en une maison d’hôte raffinée et accueillante, où chaque espace raconte une histoire unique, mêlant tradition viticole et modernité. Ancré dans l'identité du Château Chantegrive, ce projet valorise son héritage et son architecture. La tour du château, mise en valeur, offre une vue panoramique enrichissant l'expérience des visiteurs.",
        "Conçue comme un havre de paix, cette maison d’hôte offre une atmosphère sereine et reposante, permettant aux hôtes de savourer chaque moment. Ce lieu unique invite à la découverte des vins dans un cadre propice à la détente et à la réflexion.",
        "Ce projet incarne un lieu chaleureux où la découverte des textures et l'histoire du vin se reflètent à travers l'architecture. Chaque espace est conçu pour offrir un voyage sensoriel et une mise en avant des grandes années du vin.",
        "La Maison des Vignes n'est pas simplement un hébergement, mais une véritable expérience immersive dans l'univers du vin. Nous vous invitons à découvrir cet espace unique, où chaque détail est pensé pour créer des souvenirs inoubliables et offrir une expérience exceptionnelle à nos visiteurs."
    ];

    const overviewImage = {uri: developmentplan, title: "temp", width: "60%", type: "IMAGE"};

    return (

        <ProjectTemplate
            background="bg-chantegrive-presentation-background"
            title="Le Chateau de Chantegrive"
            summary="Podensac, 33720"
            galleryMedias={ medias }
            overviewImage={ overviewImage }
            overviewParagraphs={ paragraphs }
        />
    );
}

export default Chantegrive;