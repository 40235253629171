import {Media} from "../../../model/Media/Media";

type ProjectOverviewProps = {
    overviewImage: Media;
    overviewParagraphs: string[];
    overviewParagraphsImage?: Media;
};

const ProjectOverview = (props: ProjectOverviewProps) => {

    return (
        <div className="flex flex-col xl:flex-row min-h-screen bg-white">

            <div className="flex flex-col xl:w-1/2 p-6 xl:p-10 justify-center">

                <p className="font-gildadisplay space-y-8 xl:text-lg">

                    {props.overviewParagraphs.map((paragraph, index) => (
                        <p key={paragraph}>{paragraph}</p>
                    ))}
                </p>

                {
                    props.overviewParagraphsImage &&
                    <img
                        src={ props.overviewParagraphsImage.uri }
                        alt={ props.overviewParagraphsImage.title }
                        className="pt-4"
                    />
                }
            </div>

            <div className="flex flex-col xl:w-1/2 items-center justify-center p-6 xl:p-10 max-h-screen">

                <img
                    src={props.overviewImage.uri}
                    alt={props.overviewImage.title}
                    className="max-w-[95%] max-h-[95%]"
                />
            </div>
        </div>
    );
};

export default ProjectOverview;