import DiverseProjectTemplate from "../../../../components/Projects/ProjectTemplate/DiverseProjectTemplate";
import {Media} from "../../../../model/Media/Media";
import bedroom1 from "../../../../assets/img/divers/chambres-jeunes-filles/bedroom1.png";
import bedroom2 from "../../../../assets/img/divers/chambres-jeunes-filles/bedroom2.png";
import bedroom3 from "../../../../assets/img/divers/chambres-jeunes-filles/bedroom3.png";
import bedroom4 from "../../../../assets/img/divers/chambres-jeunes-filles/bedroom4.png";
import bedroom5 from "../../../../assets/img/divers/chambres-jeunes-filles/bedroom5.png";

const Chambres = () => {

    const medias: Media[] = [
        {uri: bedroom5, title: "Visuel d'une chambre", type: "IMAGE"},
        {uri: bedroom1, title: "Visuel d'une chambre", type: "IMAGE"},
        {uri: bedroom2, title: "Visuel d'une chambre", type: "IMAGE"},
        {uri: bedroom3, title: "Visuel d'une chambre", type: "IMAGE"},
        {uri: bedroom4, title: "Visuel d'une chambre", type: "IMAGE"},
    ];

    const overviewParagraphs: string[] = [
        "Rénovation de Chambres de Jeunes Filles : Un Univers Inspirant et Personnalisé.",
        "Dans ce projet de rénovation, l'objectif était de transformer deux chambres de jeunes filles en véritables espaces de vie, alliant confort, esthétique et personnalisation. Chaque détail a été pensé pour créer un environnement à la fois fonctionnel et inspirant, où chaque jeune fille pourrait s'épanouir, se détendre et se sentir pleinement chez elle.",
        "Dans ces rénovations, chaque élément a été choisi avec soin, en pensant à la fois à l’aspect visuel, au confort et à la fonctionnalité. L'optimisation de l’espace a été un point clé, pour garantir que chaque chambre soit non seulement jolie, mais aussi pratique au quotidien."
    ];

    return (
        <DiverseProjectTemplate title="Chambres de Jeunes Filles" galleryMedias={ medias } overviewParagraphs={ overviewParagraphs } />
    );
};

export default Chambres;