import DiverseProjectTemplate from "../../../../components/Projects/ProjectTemplate/DiverseProjectTemplate";
import {Media} from "../../../../model/Media/Media";
import plants from "../../../../assets/img/divers/herbier/plants.png"

const Herbier = () => {

    const medias: Media[] = [
        {uri: plants, title: "Herbier", type: "IMAGE"}
    ];

    const overviewParagraphs: string[] = [
        "L'Herbier Bienfaisant : Une Invitation à la Nature au Service de notre Bien-être.",
        "Dans un monde en perpétuelle évolution, marqué par une quête croissante de solutions naturelles et respectueuses de notre santé, j'ai créé cet herbier comme une réponse à la fois esthétique et fonctionnelle.",
        "Inspirée par la période post-Covid, où le besoin de renouer avec des pratiques simples et authentiques est devenu plus pertinent que jamais, j'ai voulu mettre en lumière les plantes et fleurs qui peuvent enrichir notre quotidien, notamment à travers des soins pour la peau, les cheveux et le bien-être général.",
        "Chaque illustration de cet herbier a été réalisée à la main, dans une démarche d'observation minutieuse et de respect de la beauté intrinsèque de chaque plante. Mon choix s’est porté sur des végétaux que l'on peut trouver autour de nous, facilement accessibles et offrant des vertus thérapeutiques reconnues. De l’aloe vera à la camomille, en passant par la lavande, chaque fleur et chaque feuille ici présentée sont porteuses de secrets de soin et de guérison que la nature nous offre."
    ];

    return (
        <DiverseProjectTemplate title="Herbier" galleryMedias={ medias } overviewParagraphs={ overviewParagraphs } inverted={ true } className="bg-white" />
    );
}

export default Herbier;