import ProjectTemplate from "../../../components/Projects/ProjectTemplate/ProjectTemplate";
import {Media} from "../../../model/Media/Media";
import office1 from "../../../assets/img/maison-ecocitoyenne/office_1.png";
import office2 from "../../../assets/img/maison-ecocitoyenne/office_2.png";
import office3 from "../../../assets/img/maison-ecocitoyenne/office_3.png";
import office4 from "../../../assets/img/maison-ecocitoyenne/office_4.png";
import office5 from "../../../assets/img/maison-ecocitoyenne/office_5.png";
import office6 from "../../../assets/img/maison-ecocitoyenne/office_6.png";
import generalreception from "../../../assets/img/maison-ecocitoyenne/general_reception.png";
import repaircoffeereception from "../../../assets/img/maison-ecocitoyenne/repair_coffee_reception.png";
import reception from "../../../assets/img/maison-ecocitoyenne/reception.png";
import closedworkshop from "../../../assets/img/maison-ecocitoyenne/closed_workshop.png";
import openedworkshop from "../../../assets/img/maison-ecocitoyenne/opened_workshop.png";
import coffeegeneralview from "../../../assets/img/maison-ecocitoyenne/coffee_general_view.png";
import coffeecounter from "../../../assets/img/maison-ecocitoyenne/coffee_counter.png";
import coffeecounterexposition from "../../../assets/img/maison-ecocitoyenne/coffee_counter_and_exposition.png";
import coffeeexterior from "../../../assets/img/maison-ecocitoyenne/coffee_exterior.png";
import materiallibrary from "../../../assets/img/maison-ecocitoyenne/material_library.png";
import wallexposition from "../../../assets/img/maison-ecocitoyenne/wall_exposition.png";
import gateway from "../../../assets/img/maison-ecocitoyenne/gateway.png";
import repaircoffeetable from "../../../assets/img/maison-ecocitoyenne/repair_coffe_table.png";
import entrance from "../../../assets/img/maison-ecocitoyenne/entrance.png";
import terrace from "../../../assets/img/maison-ecocitoyenne/terrace.png";
import togrocerystore from "../../../assets/img/maison-ecocitoyenne/to_grocery_store.png";
import plan from "../../../assets/img/maison-ecocitoyenne/plan.png";

const MaisonEcoCitoyenne = () => {

    const medias: Media[] = [
        {uri: office1, title: "Visuel des bureaux", type: "IMAGE"},
        {uri: office2, title: "Visuel des bureaux", type: "IMAGE"},
        {uri: office3, title: "Visuel des bureaux", type: "IMAGE"},
        {uri: office4, title: "Visuel des bureaux", type: "IMAGE"},
        {uri: office5, title: "Visuel des bureaux", type: "IMAGE"},
        {uri: office6, title: "Visuel des bureaux", type: "IMAGE"},
        {uri: generalreception, title: "Visuel de la réception générale", type: "IMAGE"},
        {uri: repaircoffeereception, title: "Visuel de la réception du repair café", type: "IMAGE"},
        {uri: reception, title: "Visuel de la réception", type: "IMAGE"},
        {uri: closedworkshop, title: "Visuel de l'atelier fermé", type: "IMAGE"},
        {uri: openedworkshop, title: "Visuel de l'atelier ouvert", type: "IMAGE"},
        {uri: coffeegeneralview, title: "Visuel général du café", type: "IMAGE"},
        {uri: coffeecounter, title: "Visuel du comptoir du café", type: "IMAGE"},
        {uri: coffeecounterexposition, title: "Visuel du comptoir du café et de l'exposition", type: "IMAGE"},
        {uri: coffeeexterior, title: "Visuel de l'extérieur du café", type: "IMAGE"},
        {uri: materiallibrary, title: "Visuel de la matériauthèque", type: "IMAGE"},
        {uri: wallexposition, title: "Visuel de l'exposition au mur", type: "IMAGE"},
        {uri: gateway, title: "Visuel de la passerelle", type: "IMAGE"},
        {uri: repaircoffeetable, title: "Visuel de la table du café", type: "IMAGE"},
        {uri: entrance, title: "Visuel de l'entrée", type: "IMAGE"},
        {uri: terrace, title: "Visuel de la terrasse", type: "IMAGE"},
        {uri: togrocerystore, title: "Visuel du chemin vers l'épicerie", type: "IMAGE"},
    ];

    const overviewParagraphs = [
        "Transformer une ancienne usine de maïs en un lieu de vie accueillant la Maison Éco-Citoyenne des Landes.",
        "Ce projet vise à métamorphoser cet espace industriel en un centre dédié à l'information, l'éducation et le partage autour des enjeux environnementaux et citoyens. Avec une approche résolument éco-responsable, la Maison Éco-Citoyenne met en valeur des solutions durables tout en offrant un cadre apaisant.",
        "Revisitée pour adopter une media moderne et attrayante, elle invite chacun à créer son propre cheminement à travers des thématiques d'actualité. Ce lieu unique, en connexion avec la nature, devient un espace d'inspiration et de réflexion, conçu pour éveiller les consciences et encourager les échanges."
    ];

    const overviewImage = {uri: plan, title: "Plan de la maison écocitoyenne", type: "IMAGE"};

    return (

        <ProjectTemplate
            background="bg-maisonecocitoyenne-presentation-background"
            title="La Maison Éco-Citoyenne"
            summary="Saint Pierre du Mont, 40280"
            galleryMedias={ medias }
            overviewImage={ overviewImage }
            overviewParagraphs={ overviewParagraphs }
        />
    );
};

export default MaisonEcoCitoyenne;