import DiverseProjectTemplate from "../../../../components/Projects/ProjectTemplate/DiverseProjectTemplate";
import {Media} from "../../../../model/Media/Media";
import elevation from "../../../../assets/img/divers/cuisine-haspel/elevation.png";
import plan from "../../../../assets/img/divers/cuisine-haspel/plan.png";
import kitchen1 from "../../../../assets/img/divers/cuisine-haspel/kitchen_1.png";
import kitchen2 from "../../../../assets/img/divers/cuisine-haspel/kitchen_2.png";

const CuisineHaspel = () => {

    const medias: Media[] = [
        {uri: elevation, title: "Élevation", type: "IMAGE"},
        {uri: plan, title: "Plan de la cuisine", type: "IMAGE"},
        {uri: kitchen1, title: "Visuel de la cuisine", type: "IMAGE"},
        {uri: kitchen2, title: "Visuel de la cuisine", type: "IMAGE"},
    ];

    const overviewParagraphs: string[] = [
        "Projet de rénovation de la cuisine de la famille Haspel, une réalisation alliant design, fonctionnalité et convivialité. L'objectif : créer un espace chaleureux et moderne parfaitement adapté aux besoins quotidiens et aux moments partagés en famille.",
        "Cette transformation a mis en valeur des matériaux élégants, une palette de couleurs harmonieuses et des solutions astucieuses pour optimiser l'espace. Découvrez comment chaque détail a été pensé pour refléter le style unique des Haspel tout en rendant leur cuisine aussi belle que pratique."
    ];

    return (
        <DiverseProjectTemplate title="La cuisine Haspel" galleryMedias={ medias } overviewParagraphs={ overviewParagraphs } withNavbar={ true } />
    );
};

export default CuisineHaspel;