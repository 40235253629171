import {Media} from "../../../../model/Media/Media";
import drawingboard from "../../../../assets/img/divers/studio-danse/drawing_board.png";
import DiverseProjectTemplate from "../../../../components/Projects/ProjectTemplate/DiverseProjectTemplate";

const DanseStudio = () => {

    const videoUri = require("../../../../assets/img/divers/studio-danse/video.mp4");

    const medias: Media[] = [
        {uri: drawingboard, title: "Planche de dessins", type: "IMAGE"},
        {uri: videoUri, title: "Vidéo de présentation", type: "VIDEO"}
    ];

    const overviewParagraphs: string[] = [
        "La Danseuse : Projet de construction d'un studio de danse avec dépendance pour la professeur.",
        "Dans le cadre de notre projet architectural, nous avons conçu un studio de danse innovant, accompagné d'une dépendance pour la professeur, dans le but de répondre aux besoins spécifiques d'un espace dédié à la pratique du pôle danse. Ce choix de discipline, alliant force et fluidité, nous a permis de penser l'architecture comme une extension du mouvement, en explorant comment l'espace peut interagir avec les gestes des danseurs.",
        "Concept et Approche Architecturale :",
        "Le pôle danse, à la fois élégant et exigeant, nous a inspiré pour jouer sur la notion de mouvement, mais aussi sur la relation entre l'espace et l'objet qui incarne ce mouvement : la barre de pôle. Cet élément central du studio, sur lequel les danseurs s'appuient pour leurs figures, devient un point d'ancrage symbolique dans l'architecture. Ainsi, l'implantation des espaces a été pensée de manière à favoriser une fluidité dans le déplacement, que ce soit dans le studio principal ou dans les espaces annexes, comme la dépendance de la professeur.",
        "Le Studio de Danse : Un Espace de Liberté et d’Expression.",
        "Le studio est conçu pour offrir un espace fonctionnel et polyvalent, tout en étant esthétique. La barre de pôle, intégrée dans le design du studio, devient à la fois un objet technique et un élément sculptural, jouant sur la verticalité et la torsion, des caractéristiques propres à cette discipline.",
        "La Dépendance de la Professeur : Un Espace Intime et Fonctionnel.",
        "La dépendance de la professeur, séparée du studio, est pensée comme un espace intime et pratique. Ce volume a été conçu pour offrir calme et intimité tout en étant fonctionnel, avec un accès direct et discret au studio pour faciliter la gestion des cours.",
        "L’architecture de la dépendance s’inspire du principe de simplicité et d’efficacité, tout en offrant des matériaux et une esthétique en cohérence avec l’ensemble du projet."
    ];

    return (
        <DiverseProjectTemplate title="Studio de danse" galleryMedias={ medias } overviewParagraphs={ overviewParagraphs } inverted={ true } className="bg-white" />
    );
};

export default DanseStudio;