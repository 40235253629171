import {Route, Routes} from "react-router-dom";
import Main from "./Main/Main";
import useScrollToTop from "../hooks/ScrollToTop/useScrollToTop";
import Chantegrive from "./Projects/Chantegrive/Chantegrive";
import {AnimationProvider} from "../context/AnimationContext/AnimationContext";
import MaisonEcoCitoyenne from "./Projects/MaisonEcoCitoyenne/MaisonEcoCitoyenne";
import BuildingPage from "../components/BuildingPage/BuildingPage";
import MassageNelly from "./Projects/MassageNelly/MassageNelly";
import Divers from "./Projects/Divers/Divers";

declare global {
    interface Window {
        _env_: any;
    }
}

const App = () => {

    useScrollToTop();

    return(
        <AnimationProvider>
            <Routes>
                <Route path="/" element={ <Main /> } />
                <Route path="/chateau-de-chantegrive" element={ <Chantegrive /> } />
                <Route path="/maison-eco-citoyenne" element={ <MaisonEcoCitoyenne /> } />
                <Route path="/massage-de-nelly" element={ <MassageNelly /> } />
                <Route path="/divers" element={ <Divers /> } />
                <Route path="/projet-en-construction" element={ <BuildingPage /> } />
            </Routes>
        </AnimationProvider>
    );
};

export default App;