import ProjectCard from "./ProjectCard/ProjectCard";

import chantegriveCardImage from "../../assets/img/chateau-chantegrive/card.png";
import maisonecocitoyenneCardImage from "../../assets/img/maison-ecocitoyenne/card.png";
import diversCardImage from "../../assets/img/divers/card.png";
import massageNellyCardImage from "../../assets/img/massage-nelly/card.png";

const Projects = () => {

    return (
        <div id="projects" className="min-h-screen bg-floral-white flex justify-center">

            <div className="flex flex-col lg:flex-row w-[98%]">

                <div className="flex flex-col space-y-6 lg:space-y-0 lg:justify-between lg:w-2/5">

                    <div className="text-5xl lg:text-6xl font-gildadisplay italic pt-4 px-6 lg:pt-20 lg:pl-12">
                        <h1>Mes</h1>
                        <h1>Projets</h1>
                        <h1>Réalisés</h1>
                    </div>

                    <div className="flex flex-col space-y-6 px-6 lg:px-12 lg:pb-20 lg:w-3/4 font-gildadisplay italic">
                        <p>
                            Chaque projet présenté ici est le fruit d'une collaboration harmonieuse entre créativité et expertise technique.
                            À travers une approche personnalisée et innovante, des idées ont été transformées en espaces vivants et inspirants.
                            Chaque réalisation reflète une histoire unique, une combinaison de créativité, de précision et d'attention aux détails.
                        </p>
                        <p>
                            Je vous invite à parcourir ces exemples de mon travail, où chaque espace a été conçu pour répondre aux besoins et aux rêves des clients.
                            Que vous soyez à la recherche d'inspiration pour votre propre projet ou simplement curieux de découvrir mon univers,
                            ces créations vous offriront un aperçu de mon savoir-faire et de ma vision artistique.
                        </p>
                    </div>
                </div>

                <div className="flex flex-col flex-grow space-y-4 py-4 px-4 lg:px-0">

                    <ProjectCard imgUri={chantegriveCardImage } label="Le chateau de Chantegrive" projectUri="/chateau-de-chantegrive" />
                    <ProjectCard imgUri={maisonecocitoyenneCardImage } label="La Maison Éco-Citoyenne" projectUri="/maison-eco-citoyenne" />
                    <ProjectCard imgUri={massageNellyCardImage } label="Les massages de Nelly" projectUri="/massage-de-nelly" />
                    <ProjectCard imgUri={diversCardImage } label="Projets divers" projectUri="/divers" />
                </div>
            </div>
        </div>
    );
};

export default Projects;