import {Media} from "../../../model/Media/Media";
import React from "react";
import ProjectGalleryMedia from "./ProjectGalleryImage/ProjectGalleryMedia";

type ProjectGalleryProps = {
    medias: Media[];
};

const ProjectGallery = (props: ProjectGalleryProps) => {

    return (

        <div className="min-h-screen bg-linen p-6">
            <div className="p-5 sm:p-8">
                <div className="columns-1 gap-5 sm:columns-2 sm:gap-8 md:columns-3 lg:columns-4 [&>img:not(:first-child)]:mt-8">
                    {props.medias.map((media, index) => (
                        <ProjectGalleryMedia key={ media.uri } id={ index } media={ media } />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ProjectGallery;