import { Media } from "../../../../model/Media/Media";
import React, { useState, useEffect } from "react";
import { RxCross2 } from "react-icons/rx";

type ProjectGalleryMediaProps = {
    id: number;
    media: Media;
};

const ProjectGalleryMedia = (props: ProjectGalleryMediaProps) => {
    const [isOpened, setIsOpened] = useState(false);

    const handleImageClick = () => {
        setIsOpened(true);
    };

    const handleImageClose = () => {
        setIsOpened(false);
    }

    const handleImageStopPropagation = (event: { stopPropagation: () => void; }) => {
        event.stopPropagation();
    }

    useEffect(() => {
        if (isOpened) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        // Clean up when the component is unmounted
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpened]);

    return (
        <div>
            {
                props.media.type === 'IMAGE' &&

                <div className="relative group">
                    <img src={props.media.uri} alt={props.media.title}
                         className="group-hover:opacity-80 group-hover:cursor-pointer transition-opacity duration-300 mb-4"
                         onClick={handleImageClick} />
                    <div className="w-full absolute bottom-0 left-0">
                        <p className="p-2 text-lg font-gildadisplay text-white md:opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            {props.media.title}
                        </p>
                    </div>
                </div>
            }

            {
                props.media.type === 'IMAGE' && isOpened && (
                <div
                    className="fixed inset-0 flex bg-black bg-opacity-80 hover:cursor-pointer justify-center p-10 space-x-2 z-50"
                    onClick={handleImageClose}
                >
                    <img src={props.media.uri}
                         alt={props.media.title}
                         className="max-w-full max-h-full hover:cursor-default"
                         onClick={handleImageStopPropagation}
                    />
                    <RxCross2 className="hover:cursor-pointer text-white hover:text-gray-400 text-2xl" onClick={handleImageClose}/>
                </div>
            )}

            {
                props.media.type === 'VIDEO' &&
                <video controls>
                    <source src={ props.media.uri } type="video/mp4" />
                </video>
            }
        </div>
    );
};

export default ProjectGalleryMedia;