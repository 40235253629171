import {Media} from "../../../model/Media/Media";
import DiverseProjectPresentation from "../ProjectPresentation/DiverseProjectPresentation";
import DiverseProjectGallery from "../ProjectGallery/DiverseProjectGallery";
import ProjectNavbar from "../../Navbar/ProjectNavbar/ProjectNavbar";

type DiverseProjectTemplateProps = {
    title: string;
    galleryMedias: Media[];
    overviewParagraphs: string[];
    inverted?: boolean;
    className?: string;
    withNavbar?: boolean;
};

const DiverseProjectTemplate = (props: DiverseProjectTemplateProps) => {

    const style = `flex flex-col min-h-screen bg-floral-white space-y-2 ${props.className}`

    return (
        <div className={ style }>

            {
                props.withNavbar &&
                <ProjectNavbar />
            }

            {
                !props.inverted &&
                <div className="flex flex-grow">

                    <DiverseProjectPresentation title={ props.title } paragraphs={ props.overviewParagraphs } />

                    <DiverseProjectGallery medias={ props.galleryMedias } />
                </div>
            }

            {
                props.inverted &&
                <div className="flex flex-grow">

                    <DiverseProjectGallery medias={ props.galleryMedias } />

                    <DiverseProjectPresentation title={ props.title } paragraphs={ props.overviewParagraphs } />
                </div>
            }
        </div>
    );
};

export default DiverseProjectTemplate;