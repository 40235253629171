import {useNavigate} from "react-router-dom";

type ProjectCardProps = {
    imgUri: string;
    label: string;
    projectUri: string;
};

const ProjectCard = (props: ProjectCardProps) => {

    const navigate = useNavigate();

    const handlePageChange = () => {

        navigate(props.projectUri);
    };

    return (
        <div
            className="relative flex flex-1 items-end overflow-hidden group hover:cursor-pointer hover:opacity-80 transition-opacity duration-300 min-h-[10rem] lg:min-h-0"
            onClick={handlePageChange}
        >
            <img
                src={props.imgUri}
                alt="background"
                className="absolute object-cover w-full h-full media-zoom-transition duration-500 ease-in-out media-zoomed"
            />
            <p className="p-2 font-gildadisplay text-white md:opacity-0 group-hover:opacity-100 transition-opacity duration-300 relative z-10">
                {props.label}
            </p>
        </div>
    );
};

export default ProjectCard;