import CuisineHaspel from "./CuisineHaspel/CuisineHaspel";
import Herbier from "./Herbier/Herbier";
import Chambres from "./Chambres/Chambres";
import GoToTop from "../../../components/GoToTop/GoToTop";
import React from "react";
import DanseStudio from "./DanseStudio/DanseStudio";

const Divers = () => {

    return (
        <div className="flex flex-col relative">
            <CuisineHaspel />
            <Herbier />
            <Chambres />
            <DanseStudio />
            <GoToTop/>
        </div>
    );
};

export default Divers;