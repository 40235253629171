import GoToTop from "../../GoToTop/GoToTop";
import ProjectPresentation from "../ProjectPresentation/ProjectPresentation";
import ProjectOverview from "../ProjectOverview/ProjectOverview";
import {Media} from "../../../model/Media/Media";
import React from "react";
import ProjectGallery from "../ProjectGallery/ProjectGallery";

type ProjectTemplateProps = {
    background: string;
    title: string;
    summary?: string;
    galleryMedias: Media[];
    overviewImage: Media;
    overviewParagraphs: string[];
    overviewParagraphsImage?: Media;
};

const ProjectTemplate = (props: ProjectTemplateProps) => {

    return (
        <div className="flex flex-col relative">

            <GoToTop/>

            <ProjectPresentation background={ props.background } title={ props.title } summary={ props.summary }/>

            <ProjectOverview overviewImage={ props.overviewImage } overviewParagraphs={ props.overviewParagraphs } overviewParagraphsImage={ props.overviewParagraphsImage }/>

            <ProjectGallery medias={ props.galleryMedias } />
        </div>
    );
};

export default ProjectTemplate;